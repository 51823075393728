(function ($) {
  Drupal.behaviors.gnavAccountV1 = {
    attach: function (context) {
      var $gnavNewUserReturnUrl = $('.js-gnav-util__account input[name=RETURN_URL]', context);
      var $signInReturnUrl = $('.js-return-user input[name=RETURN_URL]', context);

      // This is here until the perlgem signin tmpl can be changed to go to __login-landing
      $gnavNewUserReturnUrl.each(function (i, obj) {
        var $input = $(obj);

        if ($input.val() === '') {
          $input.val('/__login-landing');
        }
      });

      // Hits the account/signin.tmpl page
      $signInReturnUrl.each(function (i, obj) {
        var $input = $(obj);

        if ($input.val() === '') {
          $input.val('/__login-landing');
        }
      });
    }
  };
})(jQuery);
